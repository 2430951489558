<script lang="ts" setup>
import SpecialistAvatar from '~/components/specialist/SpecialistAvatar.vue'
import SpecialistButton from '~/components/specialist/SpecialistButton.vue'
import SpecialistGroupLabel from '~/components/specialist/SpecialistGroupLabel.vue'
import SpecialistSpecialities from '~/components/specialist/SpecialistSpecialities.vue'
import helpers from '~/helpers'
import type { ServiceData } from '~/types/services'
import type { SpecialistData } from '~/types/specialists'

const props = defineProps<{
  specialistData: SpecialistData
}>()

const router = useRouter()

const attributes = computed(() => props.specialistData.attributes)
// const description = computed(() => attributes.value.description)
const getMinPrice = (ids: number[]) => {
  return computed(() => {
    const prices = services.value
      .filter((x: ServiceData) => ids.includes(Number(x.id)))
      .map((x: ServiceData) => x.attributes.price)

    return prices.length ? Math.min(...prices) : null
  })
}
const experience = computed(() => attributes.value.experience)
const minPriceOffline = getMinPrice([6, 16, 19, 21])
const minPriceOnline = getMinPrice([17, 20, 27, 29, 30])
const minPriceOther = getMinPrice([18, 22, 23, 24, 25, 26, 28])
const services = computed(() => props.specialistData.relationships.services?.data ?? [])
const specialities = computed(() => props.specialistData.relationships.specialities?.data ?? [])
</script>

<template>
  <article
    class="box-border h-full !cursor-pointer rounded-3xl bg-white p-3 md:p-5"
    itemscope
    itemtype="https://schema.org/Person"
    @click.prevent="router.push({ path: helpers.getSpecialistPath(specialistData) })"
  >
    <div class="flex h-full flex-col items-center">
      <SpecialistAvatar
        class="w-[104px] md:w-[140px]"
        :images-data="specialistData.relationships.images.data"
        size="300x300"
      />

      <div class="mt-[12px] flex w-full grow flex-col justify-between text-center md:mt-5">
        <div>
          <h1
            class="mb-1 text-[18px] font-medium leading-7 text-main-dark md:mb-2 md:text-[26px] md:leading-9"
            itemprop="name"
          >
            {{ helpers.getFullName(specialistData) }}
          </h1>

          <div class="mb-2 md:mb-4">
            <SpecialistSpecialities class="text-sm md:text-base" :specialities="specialities.slice(0, 3)" />
            <span v-if="specialities.length > 3" class="text-main-aluminum">&#8230;</span>
          </div>

          <SpecialistGroupLabel class="flex flex-wrap items-center justify-center" :experience="experience" />

          <div class="mb-6 mt-3 flex items-center justify-center md:mb-6 md:mt-4">
            <div class="flex items-center text-sm md:text-base">
              <template v-if="minPriceOnline || minPriceOffline">
                <div v-if="minPriceOnline" :class="{ 'mr-4': minPriceOnline }">
                  Онлайн от&nbsp;<span itemprop="price">{{ minPriceOnline }}</span>&nbsp;₽
                </div>

                <div v-if="minPriceOffline">
                  Очно от&nbsp;<span itemprop="price">{{ minPriceOffline }}</span>&nbsp;₽
                </div>
              </template>
              <div v-else-if="minPriceOther">
                от <span itemprop="price">{{ minPriceOther }}</span>&nbsp;₽
              </div>
            </div>
          </div>
        </div>

        <SpecialistButton :specialist-data="specialistData" />
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
img, source {
  display: inherit;
  object-fit: cover;
}

img {
  height: 100%;
  width: 100%;
}
</style>
